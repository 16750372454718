import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import { Form, Button } from 'react-bootstrap';
import Paper from '@mui/material/Paper';
import skills from "./skills-list.json";
import 'react-bootstrap-typeahead/css/Typeahead.css';


const Demo = ({ dataRecieved }) => {
  const [multiSelections, setMultiSelections] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [recommendedJobs, setRecommendedJobs] = useState([]);

  useEffect(() => {
    if (multiSelections) {
      let res = [];
      multiSelections.map(x => {
        res.push({ name: x.name, weight: 3 });
      });
      setSkillList(res);
    }
  }, [multiSelections]);


  useEffect(() => {
    async function fetchData () {
      const reqBody = [{
        "jobs": [],
        "skills": skillList,
        "target": ["jobs"],
        "threshold": 0.5,
        "limit": 10
      }];

      await axios.post('https://stage.jobs-ontology.greple.ai/api/v2/predict?model=TransE_v4', reqBody, {
        headers: {
          'Authorization': `Basic Z3JlcGxlOkFSVzN3RlREM3QzQmRnS0IxMTRq`
        }
      })
        .then(function (response) {
          setRecommendedJobs(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    fetchData();

  }, [skillList]);


  return (

    <Paper elevation={3}
      sx={{
        padding: "0 5%",
        width: "50vw",
        height: "50vh",
        flex: 2,
      }}
      className={!dataRecieved ? null : "bounce"}
    >

      <Form >
        <Form.Group className={!dataRecieved ? "blur" : null} style={{ marginTop: '20px' }}>
          <Form.Label column="lg">Type your competences:</Form.Label>
          <Typeahead
            id="basic-typeahead-multiple"
            labelKey="name"
            multiple
            disabled={!dataRecieved}
            onChange={setMultiSelections}
            options={skills}
            placeholder="Choose several competences..."
            selected={multiSelections}
          />
        </Form.Group>
      </Form>
      <div className='results-List'>
        {
          !recommendedJobs ? "No recommended Jobs" : (
            recommendedJobs[0]?.jobs?.map(job => <Button key={job.name} className="job-btn" variant="outline-primary" size="md">{job.name}</Button>)
          )
        }
      </div>

    </Paper>

  );
};


export default Demo;

