import React, { useState } from 'react';
import axios from "axios";
import Box from '@mui/material/Box';
import Demo from './components/Demo';
import Contact from './components/Contact';
import Info from './components/Info';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';



async function fetchData (text) {
  await axios.post('/api/slack', {
    channel: "C03ATSP3BL6",
    username: "Abdallah",
    text
  })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
}


const App = () => {

  const [dataRecieved, setDataRecieved] = useState(false);

  const saveData = async (data) => {
    await fetchData(data);
    setDataRecieved(true);
  };

  return (
    <Box
      mt={0}
      sx={{
        display: 'flex',
        margin: "2% 0%",
        padding: "0 5%",
        '& > :not(style)': {
          m: 1,
          width: "100vw",
          height: "90vh",
        },
      }}
    >
      {/* {!dataRecieved ? <Contact saveData={saveData} /> : <Info />} */}
      <Contact saveData={saveData} />
      <Demo dataRecieved={dataRecieved} />

    </Box >

  );
}

export default App;
