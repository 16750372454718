import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import grepleLogo from "./greple.png";
const theme = createTheme({
  palette: {
    primary: {
      main: '#9eb387',
    },
  },
});

export default function Contact ({ saveData, dataRecieved }) {

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const name = data.get('name');
    const company = data.get('company');
    const phone = data.get('phone');

    const res = `Name: ${name} | Email: ${email} |  Phone: ${phone} | Company:  ${company} `;

    if (name && email && company && phone) saveData(res);

  };




  return (
    <Paper elevation={3}
      sx={{
        padding: "0 5%",
        width: "50vw",
        height: "50vh",
        flex: 1,
      }}
    >
      <img className='logo' src={grepleLogo} alt="Greple Logo" />
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#BAC48C" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Contact Details
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                autoComplete="phone"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="company"
                label="Company"
                type="company"
                id="company"
                autoComplete="current-company"
              />
              <FormControlLabel
                control={
                  <>
                    <Checkbox defaultChecked value="data-agreement" sx={{
                      color: "rgb(219, 130, 130)", '&.Mui-checked': { color: "#c2cf85" }
                    }} />

                    <Link color="#c2cf85" target="_blank" rel="noopener" underline="hover" href="https://greple.de/datenschutzerklaerung/">greple privacy policy</Link>
                  </>
                }
              />

              <Button
                type="submit"
                fullWidth
                disabled={dataRecieved}
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "#9eb387" }}
              >
                Let's go
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </Paper>

  );
}